var _uploadURL = "";
var _fileName  = "";
var _type      = "";

exports.getUploadURL = function()          { return _uploadURL;      };
exports.setUploadURL = function(uploadURL) { _uploadURL = uploadURL; };

exports.getFileName  = function()          { return _fileName;       };
exports.setFileName  = function(fileName)  { _fileName = fileName;   };

exports.getType      = function()          { return _type;           };
exports.setType      = function(type)      { _type = type;           };