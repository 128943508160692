import React, { useState } from "react";
import { Link } from "@reach/router";
import { auth } from "../firebase";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      setError("Error signing in with password and email!");
      console.error("Error signing in with password and email!", error);
    });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };

  return (
    <div >
      <img id="image" className="mb-4" src="https://storage.googleapis.com/app_static/OSmix-Primary-Logo-Black.svg" alt="" width="150" height="150"></img>
      <h1 className="h3 mb-3 font-weight-normal" id="welcomeTxt">Please sign in</h1>
      <div>
        {error !== null && (
          <div>
            {error}
          </div>
        )}
        <form className="form-signin">
          <input
            type="email"
            className = "form-control"
            name="userEmail"
            value={email}
            placeholder="Email"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
          <input
            type="password"
            className = "form-control"
            name="userPassword"
            value={password}
            placeholder="Your Password"
            id="userPassword"
            onChange={(event) => onChangeHandler(event)}
          />
          <button
            className ="btn btn-lg btn-primary btn-block" 
            onClick={(event) => {
              signInWithEmailAndPasswordHandler(event, email, password);
            }}
          >
            Sign in
          </button>
        </form>
        {/* <p className="text-center my-3">or</p>
        <button className="bg-red-500 hover:bg-red-600 w-full py-2 text-white">
          Sign in with Google
        </button> */}
        <p className="text-center my-3">
          {/* Don't have an account?{" "}
          <Link to="signUp" className="text-blue-500 hover:text-blue-600">
            Sign up here
          </Link>{" "}
          <br />{" "} */}
          <Link
            to="passwordReset"
            className="text-blue-500 hover:text-blue-600"
          >
            Forgot Password?
          </Link>
        </p>
      </div>
      <p className ="mt-5 mb-3 text-muted">&copy; 2020 | OSMix Music</p>  
    </div>
  );
};

export default SignIn;
