import React, { useContext, Component } from "react";
import { UserContext } from "../providers/userProvider";
import { auth } from "../firebase";

import UploadSong from "./uploadSong";
import UploadStem from "./uploadStem";

var request = require('request');
var tokenHelper = require('../helpers/tokenHelper');

auth.onAuthStateChanged(function(user) {
  if (user) {
    var options = {
      'method': 'POST',
      'url': 'https://osmix-api-service-mdxh2upvfa-uc.a.run.app/osmixadmin/login',
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer YouCantS33ME'
      },
      body: JSON.stringify({"email":auth.currentUser.email,"password":"DuckDuckG00S3!"})
    };
    request(options, function (error, response) {
      if (error) throw new Error(error);
      const obj = JSON.parse(response.body);
      tokenHelper.setToken(obj.data.token);
    });
  } else {
    // No user is signed in.
  }
});


export class UploadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [], // is used to store file upload information
      uploadValue: 0, // Used to view the process. Upload
      filesMetadata: [], // Used to receive metadata from Firebase.
      rows: [], // draw the DataTable
      message: "",
    };
  }

  handleInit() {
    console.log("FilePond instance has initialised", this.pond);
  }
  render() {
    return (
      <div>
        <img
          id="image"
          className="mb-4"
          src="https://storage.googleapis.com/app_static/UPM_Black.svg"
          alt=""
          width="300"
          height="150"
        ></img>
        <div className="user">
          <span style={{ padding: "1rem" }}>Hello, {auth.currentUser.email}</span>
        </div>
        <br />
        <br />
        <div>
          <label>Upload your song file(s): </label>
          <UploadSong />
          
        </div>
        <div>
          <label>Upload your stem file(s): </label>
          <UploadStem />
        </div>
        <br />
        <button
          className="btn btn-lg btn-secondary btn-block"
          onClick={() => {
            auth.signOut();
          }}
        >
          Sign out
        </button>
      </div>
    );
  }
}
