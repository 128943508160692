import React, { useContext } from "react";
import { Router } from "@reach/router";
import SignIn from "./signIn";
import SignUp from "./signUp";
import { UploadPage } from "./profilePage";
import PasswordReset from "./passwordReset";
import { UserContext } from "../providers/userProvider";

function Application() {
  const user = useContext(UserContext);
  return user ? (
    <UploadPage />
  ) : (
    <Router>
      <SignUp path="signUp" />
      <SignIn path="/" />
      <PasswordReset path="passwordReset" />
    </Router>
  );
}

export default Application;
