import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDracy4thbIQapk8BAV1AQqOjmyiEgHNuk",
  authDomain: "osmix-mastering-270321.firebaseapp.com",
  databaseURL: "https://osmix-mastering-270321.firebaseio.com",
  projectId: "osmix-mastering-270321",
  storageBucket: "osmix-mastering-270321.appspot.com",
  messagingSenderId: "807219358101",
  appId: "1:807219358101:web:021a72294dd451722f2e9c",
  measurementId: "G-G7GCV1F3MY",
};

const provider = new firebase.auth.GoogleAuthProvider();

const store = firebase.initializeApp(firebaseConfig);
export default store;

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email, displayName, photoURL } = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
      });
    } catch (error) {
      console.errot("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async (uid) => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data(),
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};
