import React from "react";
import Application from "./components/application";
import UserProvider from "./providers/userProvider";
import "./App.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";


function App() {
  return (
    <UserProvider>
      <Application />
    </UserProvider>
  );
}

export default App;
