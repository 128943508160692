import React, { useState, Component } from "react";
// Import React FilePond
import { FilePond, File } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";

var request = require("request");
var tokenHelper = require("../helpers/tokenHelper");
var uploadHelper = require("../helpers/uploadHelper");

class UploadStem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [], // is used to store file upload information
      uploadValue: 0, // Used to view the process. Upload
      filesMetadata: [], // Used to receive metadata from Firebase.
      rows: [], // draw the DataTable
      messag: "",
    };
  }

  handleInit() {
    console.log(
      "FilePond instance for uploading stems has been initialised",
      this.pond
    );
  }

  uploadFile(fieldName, file, metadata, load, error, progress, abort) {
    uploadHelper.setFileName(file.name);
    uploadHelper.setType("stem");

    console.log("Upload started for: " + file.name);

    var fileSize = file.size;
    console.log("Size: " + fileSize);

    var fileObj = this.pond.getFile();

    var authObj = "Bearer " + tokenHelper.getToken();
    var parameters = {
      method: "POST",
      url: "https://osmix-api-service-mdxh2upvfa-uc.a.run.app/v1/upload-url",
      headers: {
        "Content-Type": "application/json",
        Authorization: authObj,
      },
      body: JSON.stringify({
        filename: uploadHelper.getFileName(),
        type: uploadHelper.getType(),
      }),
    };

    const getUploadURL = function (parameters) {
      request(parameters, function (error, response, data) {
        if (error) throw new Error(error);
        const obj = JSON.parse(response.body);
        uploadHelper.setUploadURL(obj.data);
        uploadTask();
      });
    };
    getUploadURL(parameters);

    const uploadTask = function () {
      const formData = new FormData();
      formData.append(fieldName, file, file.name);

      let xhr = new XMLHttpRequest();
      xhr.addEventListener("readystatechange", function () {
        if (this.readyState === 4) {
        }
      });

      xhr.open("PUT", uploadHelper.getUploadURL(), true);
      xhr.setRequestHeader("Content-Type", "audio/basic");
      
      xhr.upload.onprogress = (e) => {
        progress(e.lengthComputable, e.loaded, e.total);
      };

      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          // the load method accepts either a string (id) or an object
          load(xhr.responseText);
        } else {
          // Can call the error method if something is wrong, should exit after
          error("oh no");
        }
      };
      xhr.send(file);
    };
  }

  render() {
    return (
      <div className="UploadStem">
        <FilePond
          name={"stemPond"}
          ref={(ref) => (this.pond = ref)}
          files={this.state.files}
          acceptedFileTypes={"audio/wav"}
          allowFileEncode={true}
          fileValidateTypeDetectType={(source, type) =>
            new Promise((resolve, reject) => {
              // Do custom type detection here and return with promise
              resolve(type);
            })
          }
          allowMultiple={true}
          maxFiles={5}
          instantUpload={false}
          server={{ process: this.uploadFile.bind(this) }}
         
          oninit={() => this.handleInit()}
          onupdatefiles={(fileItems) => {
            this.setState({
              files: fileItems.map((fileItem) => fileItem.file),
            });
          }}
        ></FilePond>
      </div>
    );
  }
}

export default UploadStem;
